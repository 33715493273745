// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function BrandSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'brandid', label: 'ID' },
    { key: 'brandname', label: 'Brand' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/brandsql/"
      columns={columns}
      itemName="Brand"
      dataKey="brandname"
      idKey="brandid"
      pageName="brand"
      storageKey="brandSqlTableSortState"
      cancelKey="brandcancel"
    />
  );
}

export default BrandSqlTable;