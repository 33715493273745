// src/components/service_page/ServiceJobAddF.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/apiClient';
import { Modal, Button } from 'react-bootstrap';
import JobServiceNameTable from './service_sql/JobServiceNameTable'; // Import JobServiceNameTable

function ServiceJobAddF({ servsellid, job, onSave, onCancel }) {
  // Initialize form state
  const [formData, setFormData] = useState({
    servjobname: 'Full Service', // Default job name
    idservicename: 1, // Default job service ID
    servjobcomment: '',
    servjobprice: 0,
    servjobdisc: 0,
    servjobqty: 1,
  });

  const [showConfirm, setShowConfirm] = useState(false);
  const [showJobServiceNameTable, setShowJobServiceNameTable] = useState(false); // State to manage JobServiceNameTable visibility
  const [selectedJobServiceId, setSelectedJobServiceId] = useState(1); // Set default job service ID to 1
  const [showSuccess, setShowSuccess] = useState(false); // Add this line
  
  useEffect(() => {
    if (job) {
      setFormData({
        servjobname: job.servicename || 'Full Service',
        idservicename: job.idservicename || 1,
        servjobcomment: job.servjobcomment || '',
        servjobprice: job.servjobprice ? job.servjobprice.toString() : '0',
        servjobdisc: job.servjobdisc ? job.servjobdisc.toString() : '0',
        servjobqty: job.servjobqty ? job.servjobqty.toString() : '1',
      });
      setSelectedJobServiceId(job.idservicename || 1);
    }
  }, [job]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') {
      if (value === '' || /^[0-9\b]+$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.servjobname) newErrors.servjobname = 'Job name is required.';
    if (formData.servjobprice === '') newErrors.servjobprice = 'Price is required.';
    if (formData.servjobqty === '') newErrors.servjobqty = 'Quantity is required.';
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    try {
      const submissionData = {
        ...formData,
        servsellid: parseInt(servsellid, 10),
        servjobcancle: false,
        servjobtimestamp: new Date().toISOString(),
        servjobprice: formData.servjobprice === '' || formData.servjobprice == null ? 0 : parseInt(formData.servjobprice, 10),
        servjobdisc: formData.servjobdisc === '' || formData.servjobdisc == null ? 0 : parseInt(formData.servjobdisc, 10),
        servjobqty: formData.servjobqty === '' || formData.servjobqty == null ? 1 : parseInt(formData.servjobqty, 10),
        servjobname: selectedJobServiceId,
      };
  
      let response;
      if (job && job.idservjob) {
        // Update existing job
        response = await axiosInstance.put(`/update-service-job/${job.idservjob}/`, submissionData);
      } else {
        // Add new job
        response = await axiosInstance.post('/add-service-job/', submissionData);
      }
  
      console.log('Response from API:', response.data);
      setShowConfirm(false);
      setShowSuccess(true);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  // Handle job service name selection
  const handleJobServiceNameSelect = (jobService) => {
    setFormData({
      ...formData,
      servjobname: jobService.servicename,
      servjobprice: jobService.serviceprice, // Set job price
    });
    setSelectedJobServiceId(jobService.idservicename);
    setShowJobServiceNameTable(false);
  };
    // Handle focus event to clear '0' when field is focused
    const handleFocus = (e) => {
      const { name } = e.target;
      if ((name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') && formData[name] === '0') {
        setFormData({
          ...formData,
          [name]: '',
        });
      }
    };

  const handleBlur = (e) => {
    const { name } = e.target;
    if ((name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') && (formData[name] === '' || formData[name] == null)) {
      setFormData({
        ...formData,
        [name]: '0',
      });
    }
  };

  return (
    <div className="container mt-3">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="servjobname" className="form-label">Job Name</label>
          <input
            type="text"
            className="form-control"
            id="servjobname"
            name="servjobname"
            value={formData.servjobname}
            readOnly
            onClick={() => setShowJobServiceNameTable(true)} // Open JobServiceNameTable on click
            style={{ cursor: 'pointer' }}
          />
        </div>
        {/* Conditionally render JobServiceNameTable or the rest of the form */}
        {showJobServiceNameTable ? (
          <JobServiceNameTable
            onSelect={handleJobServiceNameSelect} // Handle job service name selection
            onClose={() => setShowJobServiceNameTable(false)} // Handle close action
          />
        ) : (
          <>
            <div className="mb-3">
              <label htmlFor="servjobcomment" className="form-label">Job Comment</label>
              <input
                type="text"
                className="form-control"
                id="servjobcomment"
                name="servjobcomment"
                value={formData.servjobcomment}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="servjobprice" className="form-label">Job Price</label>
              <input
                type="text"
                className="form-control"
                id="servjobprice"
                name="servjobprice"
                value={formData.servjobprice}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="servjobdisc" className="form-label">Job Discount</label>
              <input
                type="text"
                className="form-control"
                id="servjobdisc"
                name="servjobdisc"
                value={formData.servjobdisc}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="servjobqty" className="form-label">Job Quantity</label>
              <input
                type="text"
                className="form-control"
                id="servjobqty"
                name="servjobqty"
                value={formData.servjobqty}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </div>

            <button type="submit" className="btn btn-primary me-2">Create</button>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

            <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Creation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to create this job entry?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirm(false)}>Cancel</Button>
                <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
              </Modal.Footer>
            </Modal>
        </>
        )}
      </form>
            {/* Success Modal */}
            <Modal show={showSuccess} onHide={() => {
                setShowSuccess(false);
                onSave(); // Close the form and refresh data
              }}>
              <Modal.Header closeButton>
                <Modal.Title>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>The job has been added successfully!</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => {
                  setShowSuccess(false);
                  onSave(); // Close the form and refresh data
                }}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
    </div>
  );
}

export default ServiceJobAddF;