//src/components/AddorEditForm.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/apiClient';
import CatSqlTable from './product_sql/CatSqlTable';
import TypeSqlTable from './product_sql/TypeSqlTable';
import BrandSqlTable from './product_sql/BrandSqlTable';
import ModelSqlTable from './product_sql/ModelSqlTable';
import SizeSqlTable from './product_sql/SizeSqlTable';
import ColorSqlTable from './product_sql/ColorSqlTable';
import BarcodeScanner from './BarcodeScanner'; // Import the BarcodeScanner component
import { Modal } from 'react-bootstrap'; // For modal display


function AddOrEditForm({ product, onSave, onCancel, formTitle }) {
  const [formData, setFormData] = useState({
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    price: '',
    barcode: ''
  });

  const [productName, setProductName] = useState('');
  const [originalBarcode, setOriginalBarcode] = useState(''); // Store the original barcode
  const [isCatSqlTableVisible, setIsCatSqlTableVisible] = useState(false);
  const [isTypeSqlTableVisible, setIsTypeSqlTableVisible] = useState(false);
  const [isBrandSqlTableVisible, setIsBrandSqlTableVisible] = useState(false);
  const [isModelSqlTableVisible, setIsModelSqlTableVisible] = useState(false);
  const [isSizeSqlTableVisible, setIsSizeSqlTableVisible] = useState(false);
  const [isColorSqlTableVisible, setIsColorSqlTableVisible] = useState(false);

  const [showScanner, setShowScanner] = useState(false); // State for showing/hiding barcode scanner

  useEffect(() => {
    if (product) {
      setFormData({
        categoryname: product.categoryname || '',
        typename: product.typename || '',
        brandname: product.brandname || '',
        modelname: product.modelname || '',
        sizename: product.sizename || '',
        colorname: product.colorname || '',
        price: product.price || '',
        barcode: product.barcode || ''
      });
      setOriginalBarcode(product.barcode || ''); // Set the original barcode
    } else {
      setFormData({
        categoryname: '-',
        typename: '-',
        brandname: '-',
        modelname: '-',
        sizename: '-',
        colorname: '-',
        price: '0',
        barcode: '-'
      });
      setOriginalBarcode('-'); // Set the original barcode
    }
  }, [product]);

  useEffect(() => {
    const { categoryname, typename, brandname, modelname, sizename, colorname } = formData;
    setProductName(`${categoryname} ${typename} ${brandname} ${modelname} ${sizename} ${colorname}`);
  }, [formData]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleBarcodeDoubleClick = () => {
    setShowScanner(true); 
  };
  
  const handleBarcodeScanned = (scannedBarcode) => {
    setFormData({
      ...formData,
      barcode: scannedBarcode
    });
    setShowScanner(false);
  };


  const expectedDataTypes = {
    prodbarcode: 'string',
    prodcategory: 'number',
    prodtype: 'number',
    prodbrand: 'number',
    prodsize: 'number',
    prodcolor: 'number',
    prodprice1: 'number',
    instalmentprice: 'number',
    prodprice4: 'number',
    prodsupp: 'number',
    prodcost: 'number',
    prodmodel: 'number',
    prodcancel: 'boolean',
    prodtimestamp: 'string', // Date should be in ISO string format
    prodname: 'string',
    promotiondetail: 'number'
  };

  const validateData = (data, expectedTypes) => {
    for (const key in expectedTypes) {
      if (data[key] !== undefined && data[key] !== null) {
        const expectedType = expectedTypes[key];
        const actualType = typeof data[key];
        if (actualType !== expectedType) {
          console.error(`Invalid type for ${key}: expected ${expectedType}, got ${actualType}`);
          return false;
        }
      }
    }
    return true;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Fetch IDs and perform duplicate checks...
      const checkDuplicateName = await axiosInstance.get(`/check-duplicate/`, {
        params: { prodname: formData.productName }
      });

      if (checkDuplicateName.data.duplicate_name) {
        alert('Have duplicate name');
        return;
      }

      if (formTitle === 'แก้ไข' && formData.barcode !== originalBarcode) {
        const checkDuplicateBarcode = await axiosInstance.get(`/check-duplicate/`, {
          params: { prodbarcode: formData.barcode }
        });

        if (checkDuplicateBarcode.data.duplicate_barcode) {
          alert('ซ้ำกับ Barcode ที่มีอยู่แล้ว');
          return;
        }
      }

      if (formTitle === 'สร้างใหม่' && formData.barcode !== '-') {
        const checkDuplicateBarcode = await axiosInstance.get(`/check-duplicate/`, {
          params: { prodbarcode: formData.barcode }
        });

        if (checkDuplicateBarcode.data.duplicate_barcode) {
          alert('ซ้ำกับ Barcode ที่มีอยู่แล้ว');
          return;
        }
      }
      const fetchCatId = await axiosInstance.get(`/get-catid/`, {
        params: { categoryname: formData.categoryname }
      });

      if (!fetchCatId.data.catid) {
        alert('Category ID not found');
        return;
      }

      const fetchTypeId = await axiosInstance.get(`/get-typeid/`, {
        params: { typename: formData.typename }
      });

      if (!fetchTypeId.data.typeid) {
        alert('Type ID not found');
        return;
      }

      const fetchBrandId = await axiosInstance.get(`/get-brandid/`, {
        params: { brandname: formData.brandname }
      });

      if (!fetchBrandId.data.brandid) {
        alert('Brand ID not found');
        return;
      }

      const fetchModelId = await axiosInstance.get(`/get-modelid/`, {
        params: { modelname:formData.modelname }
      });
      
      if (!fetchModelId.data.modelid) {
        alert('Model ID not found');
        return;
      }

      const fetchSizeId = await axiosInstance.get(`/get-sizeid/`, {
        params: { sizename: formData.sizename }
      });

      if (!fetchSizeId.data.sizeid) {
        alert('Size ID not found');
        return;
      }

      const fetchColorId = await axiosInstance.get(`/get-colorid/`, {
        params: { colorname: formData.colorname }
      });

      if (!fetchColorId.data.colorid) {
        alert('Color ID not found');
        return;
      }


      // Store the IDs in variables or state as needed
      const catid = fetchCatId.data.catid;
      const typeid = fetchTypeId.data.typeid;
      const brandid = fetchBrandId.data.brandid;
      const modelid = fetchModelId.data.modelid;
      const sizeid = fetchSizeId.data.sizeid;
      const colorid = fetchColorId.data.colorid;

      // alert(`Category ID: ${catid}\nType ID: ${typeid}\nBrand ID: ${brandid}\nModel ID: ${modelid}\nSize ID: ${sizeid}\nColor ID: ${colorid}`);


    // Prepare the data to be sent
    const data = {
      prodbarcode: formData.barcode,
      prodcategory: catid,
      prodtype: typeid,
      prodbrand: brandid,
      prodmodel: modelid,
      prodsize: sizeid,
      prodcolor: colorid,
      prodprice1: parseFloat(formData.price), // Convert to number
      prodname: productName,
      prodcancel: false,
      // Add other fields as necessary
    };

      // Validate the data
      if (!validateData(data, expectedDataTypes)) {
        alert('Invalid data. Please check the input fields.');
        return;
      }

     // Check the formTitle to determine whether to create or update the product
    let response;
    if (formTitle === 'แก้ไข') {
      // Update the existing product
      response = await axiosInstance.put(`/update-product/${product.prodid}/`, data);
    } else if (formTitle === 'สร้างใหม่') {
      // Create a new product
      response = await axiosInstance.post(`/add-product/`, data);
    }

    if (response.status === 201 || response.status === 200) {
      alert('Product saved successfully');
      onSave(response.data); // Call the onSave callback with the saved product data
    } else {
      alert('Failed to save product');
    }
  } catch (error) {
    console.error('There was an error!', error);
  }
  };


  const handleCategoryClick = () => {
    setIsCatSqlTableVisible(true);
    setIsTypeSqlTableVisible(false);
    setIsBrandSqlTableVisible(false);
    setIsModelSqlTableVisible(false);
    setIsSizeSqlTableVisible(false);
    setIsColorSqlTableVisible(false);
  };

  const handleTypeClick = () => {
    setIsCatSqlTableVisible(false);
    setIsTypeSqlTableVisible(true);
    setIsBrandSqlTableVisible(false);
    setIsModelSqlTableVisible(false);
    setIsSizeSqlTableVisible(false);
    setIsColorSqlTableVisible(false);
  };

  const handleBrandClick = () => {
    setIsCatSqlTableVisible(false);
    setIsTypeSqlTableVisible(false);
    setIsBrandSqlTableVisible(true);
    setIsModelSqlTableVisible(false);
    setIsSizeSqlTableVisible(false);
    setIsColorSqlTableVisible(false);
  };

  const handleModelClick = () => {
    setIsCatSqlTableVisible(false);
    setIsTypeSqlTableVisible(false);
    setIsBrandSqlTableVisible(false);
    setIsModelSqlTableVisible(true);
    setIsSizeSqlTableVisible(false);
    setIsColorSqlTableVisible(false);
  };

  const handleSizeClick = () => {
    setIsCatSqlTableVisible(false);
    setIsTypeSqlTableVisible(false);
    setIsBrandSqlTableVisible(false);
    setIsModelSqlTableVisible(false);
    setIsSizeSqlTableVisible(true);
    setIsColorSqlTableVisible(false);
  };

  const handleColorClick = () => {
    setIsCatSqlTableVisible(false);
    setIsTypeSqlTableVisible(false);
    setIsBrandSqlTableVisible(false);
    setIsModelSqlTableVisible(false);
    setIsSizeSqlTableVisible(false);
    setIsColorSqlTableVisible(true);
  };

  const handleCategorySelect = (selectedItem) => {
    setFormData({
      ...formData,
      categoryname: selectedItem.categoryname
    });
    setIsCatSqlTableVisible(false);
  };

  const handleTypeSelect = (selectedItem) => {
    setFormData({ ...formData, 
      typename: selectedItem.typename });
    setIsTypeSqlTableVisible(false);
  };

  const handleBrandSelect = (selectedItem) => {
    setFormData({ 
      ...formData, 
      brandname: selectedItem.brandname });
    setIsBrandSqlTableVisible(false);
  }

  const handleModelSelect = (selectedItem) => {
    setFormData({ ...formData, 
      modelname: selectedItem.modelname });
    setIsModelSqlTableVisible(false);
  }

  const handleSizeSelect = (selectedItem) => {
    setFormData({ ...formData, 
      sizename: selectedItem.sizename });
    setIsSizeSqlTableVisible(false);
  }

  const handleColorSelect = (selectedItem) => {
    setFormData({ 
      ...formData, 
      colorname: selectedItem.colorname
    });
    setIsColorSqlTableVisible(false);
  }

  return (
    <div className="add-or-edit-form">
      <form onSubmit={handleSubmit} method="POST">
        <h2>{formTitle}</h2>
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Category</label>
            <input
              type="text"
              className="form-control"
              name="categoryname"
              value={formData.categoryname}
              onChange={handleChange}
              onClick={handleCategoryClick}
              readOnly  // Make the input read-only
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Type</label>
            <input
              type="text"
              className="form-control"
              name="typename"
              value={formData.typename}
              onChange={handleChange}
              onClick={handleTypeClick} 
              readOnly  // Make the input read-only
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Brand</label>
            <input
              type="text"
              className="form-control"
              name="brandname"
              value={formData.brandname}
              onChange={handleChange}
              onClick={handleBrandClick}
              readOnly  // Make the input read-only
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Model</label>
            <input
              type="text"
              className="form-control"
              name="modelname"
              value={formData.modelname}
              onChange={handleChange}
              onClick={handleModelClick}
              readOnly  // Make the input read-only
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Size</label>
            <input
              type="text"
              className="form-control"
              name="sizename"
              value={formData.sizename}
              onChange={handleChange}
              onClick={handleSizeClick}
              readOnly  // Make the input read-only
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Color</label>
            <input
              type="text"
              className="form-control"
              name="colorname"
              value={formData.colorname}
              onChange={handleChange}
              onClick={handleColorClick}
              readOnly  // Make the input read-only
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Price</label>
            <input
              type="number"
              className="form-control"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Barcode</label>
            <input
              type="text"
              className="form-control"
              name="barcode"
              value={formData.barcode}
              onChange={handleChange}
              onDoubleClick={handleBarcodeDoubleClick} // Open the scanner modal on double click
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Name</label>
            <input
              type="text"
              className="form-control"
              name="productname"
              value={productName}
              readOnly  // Make the input read-only
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
        <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>Cancel</button>
      </form>
        {/* Barcode scanner modal */}
        <Modal show={showScanner} onHide={() => setShowScanner(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BarcodeScanner onBarcodeScanned={handleBarcodeScanned} />
        </Modal.Body>
        </Modal>

      {isCatSqlTableVisible && <CatSqlTable onSelect={handleCategorySelect} />}
      {isTypeSqlTableVisible && <TypeSqlTable onSelect={handleTypeSelect} />}
      {isBrandSqlTableVisible && <BrandSqlTable onSelect={handleBrandSelect} />}
      {isModelSqlTableVisible && <ModelSqlTable onSelect={handleModelSelect} />}
      {isSizeSqlTableVisible && <SizeSqlTable onSelect={handleSizeSelect} />}
      {isColorSqlTableVisible && <ColorSqlTable onSelect={handleColorSelect} />}
    </div>
  );
}

export default AddOrEditForm;
