// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function ColorSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'idprodcolor', label: 'ID' },
    { key: 'colorname', label: 'Color' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/colorsql/"
      columns={columns}
      itemName="Color"
      dataKey="colorname"
      idKey="idprodcolor"
      pageName="color"
      storageKey="colorSqlTableSortState"
      cancelKey="colorcancel"
    />
  );
}

export default ColorSqlTable;