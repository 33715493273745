// src/components/ThingtoServAddform.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import CatSqlTable from '../product_sql/CatSqlTable';
import BrandSqlTable from '../product_sql/BrandSqlTable'; 
import ModelSqlTable from '../product_sql/ModelSqlTable'; 
import SizeSqlTable from '../product_sql/SizeSqlTable'; 
import ColorSqlTable from '../product_sql/ColorSqlTable';
import { Modal, Button } from 'react-bootstrap';
//import { useDispatch } from 'react-redux';


function ServiceThingAddF({ thing, serviceId, onSave, onCancel }) {
  // Initialize state variables for form fields
  const [formData, setFormData] = useState({
    servprodcat: 7, // Default to '7'
    servprodbrand: '',
    servprodmodel: '',
    servprodsize: '',
    servcomment: '',
    servprodcolor: '',
    servtosellbillid: serviceId, // Ensure this is set to a valid value
    servcancel: false,
    servsellstaff: '',
    servfinishdateplan: '',
    servtimestamp: new Date().toISOString(),
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState(''); // State to store API error messages
  //const dispatch = useDispatch();

  const [showCatSqlTable, setShowCatSqlTable] = useState(false);
  const [servProdCatName, setServProdCatName] = useState('bike'); // Default to 'bike'
  const [showBrandSqlTable, setShowBrandSqlTable] = useState(false);
  const [servProdBrandName, setServProdBrandName] = useState('');
  const [showModelSqlTable, setShowModelSqlTable] = useState(false);
  const [servProdModelName, setServProdModelName] = useState('');
  const [showSizeSqlTable, setShowSizeSqlTable] = useState(false);
  const [servProdSizeName, setServProdSizeName] = useState('');
  const [showColorSqlTable, setShowColorSqlTable] = useState(false);
  const [servProdColorName, setServProdColorName] = useState('');
  // Add state to manage modal visibility
  const [showConfirm, setShowConfirm] = useState(false);

  // Populate form with existing data if editing
  useEffect(() => {
    if (thing) {
      setFormData({
        idservicesell: thing.idservicesell || '',
        servprodcat: thing.catid || 7,
        servprodbrand: thing.brandid || '',
        servprodmodel: thing.modelid || '',
        servprodsize: thing.idprodsize || '',
        servcomment: thing.servcomment || '',
        servprodcolor: thing.idprodcolor || '',
        servtosellbillid: thing.serviceid || '',
        servcancel: thing.servcancel || false,
        servsellstaff: thing.servsellstaff || '',
        servfinishdateplan: thing.servfinishdateplan || '',
        servtimestamp: thing.servtimestamp || new Date().toISOString(),
      });
      setServProdCatName(thing.categoryname || 'bike'); // Use thing.categoryname or 'bike'
      setServProdBrandName(thing.brandname || '');
      setServProdModelName(thing.modelname || '');
      setServProdSizeName(thing.sizename || '');
      setServProdColorName(thing.colorname || '');
    }
  }, [thing, serviceId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.servprodcat) newErrors.servprodcat = 'Product category is required.';
    if (!formData.servtosellbillid) newErrors.servtosellbillid = 'Service ID is required.';
    // Add more validation rules as needed

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Data being sent:', formData);

    if (!validate()) return;

    setShowConfirm(true);
  };

const handleConfirm = async () => {
  setShowConfirm(false);
  // Handle null values for servprodcolor
    const formDataToSubmit = {
      ...formData,
      idservicesell: thing ? thing.idservicesell : null,
      servprodcolor: formData.servprodcolor ? formData.servprodcolor : null,
      servprodbrand: formData.servprodbrand ? formData.servprodbrand : null,
      servprodmodel: formData.servprodmodel ? formData.servprodmodel : null,
      servprodsize: formData.servprodsize ? formData.servprodsize : null,
      servsellstaff: formData.servsellstaff ? formData.servsellstaff : null,
      servfinishdateplan: formData.servfinishdateplan ? formData.servfinishdateplan : null,
    };
    console.log('Data being sent:', formDataToSubmit);
    try {
      let response;
      if (thing && thing.idservicesell) {
        // Update existing entry
        response = await axiosInstance.put(`/update-thingtoserv/${thing.idservicesell}/`, formDataToSubmit);
      } else {
        // Create new entry
        response = await axiosInstance.post(`/add-thingtoserv/`, formDataToSubmit);
      }
      console.log('Response data:', response.data); // Log the response data

      if (response.data && response.data.idservicesell) {
        onSave(response.data); // Make sure to pass the updated `thing` data with `idservicesell`
      } else {
        console.error('Invalid response data:', response.data);
      }
     onCancel();
      
    } catch (error) {
      console.error('Error saving data:', error);
      if (error.response) {
        // Server responded with a status other than 200 range
        setApiError(error.response.data.message || 'There was an error saving the data.');
      } else if (error.request) {
        // Request was made but no response received
        setApiError('No response received from the server.');
      } else {
        // Something else happened while setting up the request
        setApiError('An error occurred while setting up the request.');
    }
  }
};

  // Function to handle cancel confirmation
  const handleCancelConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <div className="container mt-3">
      <form onSubmit={handleSubmit}>
        {/* servprodcat */}
        <div className="mb-3">
          <label htmlFor="servprodcat" className="form-label">
            Product Category
          </label>
          <input
            type="text"
            className={`form-control ${errors.servprodcat ? 'is-invalid' : ''}`}
            id="servprodcat"
            name="servprodcat"
            value={servProdCatName}
            readOnly
            onClick={() => {
              setShowColorSqlTable(false);
              setShowCatSqlTable(true);
              setShowBrandSqlTable(false);
              setShowModelSqlTable(false);
              setShowSizeSqlTable(false);
            }}
            style={{ cursor: 'pointer' }}
          />
          {errors.servprodcat && (
            <div className="invalid-feedback">{errors.servprodcat}</div>
          )}
        </div>

        {/* Conditionally render CatSqlTable or the rest of the form */}
        {showCatSqlTable ? (
          <CatSqlTable
            onSelect={(category) => {
              setFormData({ ...formData, servprodcat: category.catid });
              setServProdCatName(category.categoryname);
              setShowCatSqlTable(false);
            }}
            onClose={() => setShowCatSqlTable(false)} // Handle close action
          />
        ) : (
          <>
            {/* servprodbrand */}
            <div className="mb-3">
              <label htmlFor="servprodbrand" className="form-label">
                Product Brand
              </label>
              <input
                type="text"
                className="form-control"
                id="servprodbrand"
                name="servprodbrand"
                value={servProdBrandName}
                readOnly
                onClick={() => {
                  setShowColorSqlTable(false);
                  setShowCatSqlTable(false);
                  setShowBrandSqlTable(true);
                  setShowModelSqlTable(false);
                  setShowSizeSqlTable(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            {showBrandSqlTable ? (
              <BrandSqlTable
                onSelect={(brand) => {
                  setFormData({ ...formData, servprodbrand: brand.brandid });
                  setServProdBrandName(brand.brandname);
                  setShowBrandSqlTable(false);
                }}
                onClose={() => setShowBrandSqlTable(false)}
              />
            ) : (
              <>
                {/* servprodmodel */}
                <div className="mb-3">
                  <label htmlFor="servprodmodel" className="form-label">
                    Product Model
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="servprodmodel"
                    name="servprodmodel"
                    value={servProdModelName}
                    readOnly
                    onClick={() => {
                      setShowColorSqlTable(false);
                      setShowCatSqlTable(false);
                      setShowBrandSqlTable(false);
                      setShowModelSqlTable(true);
                      setShowSizeSqlTable(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {showModelSqlTable ? (
                  <ModelSqlTable
                    onSelect={(model) => {
                      setFormData({ ...formData, servprodmodel: model.modelid });
                      setServProdModelName(model.modelname);
                      setShowModelSqlTable(false);
                    }}
                    onClose={() => setShowModelSqlTable(false)}
                  />
                ) : (
                  <>
                    {/* servprodsize */}
                    <div className="mb-3">
                      <label htmlFor="servprodsize" className="form-label">
                        Product Size
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="servprodsize"
                        name="servprodsize"
                        value={servProdSizeName}
                        readOnly
                        onClick={() => {
                          setShowColorSqlTable(false);
                          setShowCatSqlTable(false);
                          setShowBrandSqlTable(false);
                          setShowModelSqlTable(false);
                          setShowSizeSqlTable(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    {showSizeSqlTable ? (
                      <SizeSqlTable
                        onSelect={(size) => {
                          setFormData({ ...formData, servprodsize: size.idprodsize });
                          setServProdSizeName(size.sizename);
                          setShowSizeSqlTable(false);
                        }}
                        onClose={() => setShowSizeSqlTable(false)}
                      />
                    ) : (
                      <>
                        {/* servprodcolor */}
                        <div className="mb-3">
                          <label htmlFor="servprodcolor" className="form-label">
                            Product Color
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="servprodcolor"
                            name="servprodcolor"
                            value={servProdColorName}
                            readOnly
                            onClick={() => {
                              setShowColorSqlTable(true);
                              setShowCatSqlTable(false);
                              setShowBrandSqlTable(false);
                              setShowModelSqlTable(false);
                              setShowSizeSqlTable(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        {showColorSqlTable ? (
                          <ColorSqlTable
                            onSelect={(color) => {
                              setFormData({ ...formData, servprodcolor: color.idprodcolor });
                              setServProdColorName(color.colorname);
                              setShowColorSqlTable(false);
                            }}
                            onClose={() => setShowColorSqlTable(false)}
                          />
                        ) : (
                          <>
                            {/* servcomment */}
                            <div className="mb-3">
                              <label htmlFor="servcomment" className="form-label">
                                Comment
                              </label>
                              <textarea
                                className="form-control"
                                id="servcomment"
                                name="servcomment"
                                rows="3"
                                value={formData.servcomment}
                                onChange={handleChange}
                              ></textarea>
                            </div>

                            <button type="submit" className="btn btn-primary me-2">
                              {thing ? 'Update' : 'Create'}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>

                            <Modal show={showConfirm} onHide={handleCancelConfirm}>
                              <Modal.Header closeButton>
                                <Modal.Title>Confirm Creation</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Are you sure you want to create this entry?</Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelConfirm}>
                                  Cancel
                                </Button>
                                <Button variant="primary" onClick={handleConfirm}>
                                  Confirm
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            {apiError && (
                              <div className="alert alert-danger mt-3">{apiError}</div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </form>
    </div>
  );
}

export default ServiceThingAddF;