// src/components/NavBar.js
import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NavBar = ({ username, handleLogout, handleSidebarToggle }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleSidebar = () => {
    handleSidebarToggle(); // Open sidebar
    setIsNavCollapsed(true); // Collapse navbar after sidebar opens
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-3" expanded={!isNavCollapsed}>
      <Container>
        <Navbar.Brand className="ms-3">Hero Bike</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleSidebar} // Only use this to toggle sidebar and collapse navbar
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => { setIsNavCollapsed(true); navigate('/'); }}>Stock</Nav.Link>
            <Nav.Link onClick={() => { setIsNavCollapsed(true); navigate('/service'); }}>Service</Nav.Link>
            <Nav.Link onClick={() => { setIsNavCollapsed(true); navigate('/adjpage'); }}>Adjust</Nav.Link>
          </Nav>
          <Nav className="ms-auto me-3">
            {username && (
              <>
                <Nav.Item className="me-3">
                  <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { setIsNavCollapsed(true); navigate('/change-password'); }}> {username} </span>
                </Nav.Item>
                <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }} variant="danger" onClick={handleLogout}>
                  Logout
                </span>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;