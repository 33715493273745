import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for grid layout

function FilterService({ onFilter, jobStatusOptions, jobCategoryOptions, selectedColumns, onColumnsChange, onAddService }) {
  const [customer, setCustomer] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('not_completed'); // Default to "not_completed"
  const [showAll, setShowAll] = useState(false); // State for the "All" checkbox
  const [selectedJobCategory, setSelectedJobCategory] = useState('งาน service หน้าร้าน'); // Default job category

  // Trigger filtering whenever the customer input or selected status changes
  useEffect(() => {
    onFilter({ customer, selectedStatus, showAll, selectedJobCategory });
  }, [customer, selectedStatus, showAll, selectedJobCategory, onFilter]);

  // Handle the "Clear" button to reset the filters
  const handleClear = () => {
    setCustomer('');
    setSelectedStatus('not_completed'); // Reset to default status
    setShowAll(false); // Uncheck the "All" checkbox
    setSelectedJobCategory('งาน service หน้าร้าน'); // Reset to default job category
    onFilter({ customer: '', selectedStatus: 'not_completed', showAll: false, selectedJobCategory: 'งาน service หน้าร้าน' }); // Reset the filter
  };

  return (
    <form className="mb-3">
      <div className="row g-0">
        {/* Status Filter */}
        <div className="col-6 d-flex align-items-center">
          <label htmlFor="statusDropdown" className="me-2 mb-0">สถานะ : </label>
          <select
            id="statusDropdown"
            className="form-select me-3 custom-select-border"
            style={{ flex: '1' }}
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="not_completed">ที่ยังไม่จบงาน</option> {/* Default filter */}
            {jobStatusOptions.map((status, index) => (
              <option key={index} value={status}>{status}</option>
            ))}
          </select>
        </div>

        {/* Customer Filter */}
        <div className="col-6 me-0">
          <div className="form-group d-flex align-items-center">
            <input
              type="text"
              id="customer"
              placeholder="ลูกค้า"
              className="form-control ms-0"
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            />
          </div>
        </div>

        {/* Job Category Filter */}
        <div className="col-6 d-flex align-items-center mt-2">
          <label htmlFor="jobCategoryDropdown" className="me-2 mb-0">งาน : </label>
          <select
            id="jobCategoryDropdown"
            className="form-select me-3 custom-select-border"
            style={{ flex: '1' }}
            value={selectedJobCategory}
            onChange={(e) => setSelectedJobCategory(e.target.value)}
          >
            <option value="">All</option>
            {jobCategoryOptions.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {/* Add the Select Columns button */}
        <div className="col-12 d-flex justify-content-end mt-2">
          {/* Add the Add Service button */}
          <button type="button" className="btn btn-success me-2" onClick={onAddService}>
            Add Service
          </button>

          {/* Existing Clear and All Button */}
          <button type="button" className="btn btn-secondary me-2" onClick={handleClear}>
            Clear
          </button>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="allCheck"
              checked={showAll}
              onChange={() => setShowAll(!showAll)}
            />
            <label className="form-check-label" htmlFor="allCheck">All</label>
          </div>
        </div>
      </div>
    </form>
  );
}

export default FilterService;