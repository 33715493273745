// src/components/adjust_page/AdjAddPage.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../utils/apiClient';
import AdjProdToAddTable from './AdjProdToAddTable';
import AdjBillToAddForm from './AdjBillToAddForm';
import FilterForm from '../FilterForm'; 
import StockTable from '../StockTable'; 
import { Modal } from 'react-bootstrap';

function AdjAddPage() {
  const [adjustComment, setAdjustComment] = useState(''); // State to store the adjust comment
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [stockData, setStockData] = useState([]); 
  const [filters, setFilters] = useState({
    name: '',
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: ''
  });
  const [seeAll, setSeeAll] = useState(false);
  const [adjProdData, setAdjProdData] = useState([]); // State to store added products
  const [selectedProduct, setSelectedProduct] = useState(null); // selected product in modal

  const isAnyFilterFilled = useCallback(() => {
    return Object.values(filters).some(value => value.trim() !== '');
  }, [filters]);

  const fetchAdjProdData = async () => {
    try {
      const response = await axiosInstance.get('/adjprodtoadd/');
      setAdjProdData(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching adjProdData:', error);
    }
  };

  const fetchStockData = useCallback(() => {
    if (!isAnyFilterFilled()) {
      setStockData([]); 
      return; 
    }

    axiosInstance.get(`/stview/`)
      .then(response => {
        setStockData(response.data); 
      })
      .catch(error => {
        console.error('There was an error fetching the stock data!', error);
      });
  }, [isAnyFilterFilled]);

  useEffect(() => {
    if (showAddProductModal) {
      fetchStockData(); 
    }
  }, [fetchStockData, showAddProductModal]);

  useEffect(() => {
    // Fetch adjProdData when the component mounts
    fetchAdjProdData();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClearFilters = () => {
    setFilters({
      name: '',
      categoryname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: ''
    });
    setStockData([]); 
  };

  const handleRefresh = () => {
    fetchStockData(); 
  };

  const handleSeeAllChange = () => {
    setSeeAll(!seeAll);
  };

  const filteredStockData = stockData.filter(item => {
    const matchesFilters = (
      (item.name || '').toLowerCase().includes(filters.name.toLowerCase()) &&
      (item.categoryname || '').toLowerCase().includes(filters.categoryname.toLowerCase()) &&
      (item.typename || '').toLowerCase().includes(filters.typename.toLowerCase()) &&
      (item.brandname || '').toLowerCase().includes(filters.brandname.toLowerCase()) &&
      (item.modelname || '').toLowerCase().includes(filters.modelname.toLowerCase()) &&
      (item.sizename || '').toLowerCase().includes(filters.sizename.toLowerCase()) &&
      (item.colorname || '').toLowerCase().includes(filters.colorname.toLowerCase()) &&
      (item.barcode || '').toLowerCase().includes(filters.barcode.toLowerCase())
    );
    const matchesSeeAll = seeAll || (item.st > 0 && !item.prodcancel);
    return matchesFilters && matchesSeeAll;
  });

  const handleAddProduct = () => {
    setShowAddProductModal(true);
  };

  const handleCloseModal = () => {
    setShowAddProductModal(false);
  };

  // Refetch adjProdData after adding products
  const handleAddToAdjust = () => {
    const requests = filteredStockData.map(item => {
      const dataToAdd = {
        catname: item.categoryname,
        typename: item.typename,
        brandname: item.brandname,
        modelname: item.modelname,
        sizename: item.sizename,
        colorname: item.colorname,
        oldstock: item.st,
        idprodtoadd: item.prodid,
        prodbarcode: item.barcode
      };
  
      return axiosInstance.post('/adjprodtoadd/', dataToAdd)
        .then(response => response.data)
        .catch(error => {
          console.error('Error adding product to adjprodtoadd:', error);
          return null;
        });
    });
  
    Promise.all(requests).then(results => {
      // Instead of updating adjProdData directly, fetch it from the server
      fetchAdjProdData();
      // Close the modal after refetching
      handleCloseModal();
    });
  };

  // Function to handle adding a single selected product
  const handleAddOneToAdjust = () => {
    if (!selectedProduct) {
      alert("Please select a product to add.");
      return;
    }
    const dataToAdd = {
      catname: selectedProduct.categoryname,
      typename: selectedProduct.typename,
      brandname: selectedProduct.brandname,
      modelname: selectedProduct.modelname,
      sizename: selectedProduct.sizename,
      colorname: selectedProduct.colorname,
      oldstock: selectedProduct.st,
      idprodtoadd: selectedProduct.prodid,
      prodbarcode: selectedProduct.barcode
    };

    axiosInstance.post('/adjprodtoadd/', dataToAdd)
      .then(() => {
        fetchAdjProdData(); // Fetch the updated adjProdData after adding
        handleCloseModal(); // Close the modal
      })
      .catch(error => {
        console.error('Error adding product to adjprodtoadd:', error);
      });
  };

  // Handle saving the adjust stock
  const handleSaveAdjustStock = async () => {
    try {
      // Step 1: Create Adjbillsql record
      const adjBillResponse = await axiosInstance.post('/adjbillsql/', {
        adjcomment: adjustComment,
        adjdatetimestamp: new Date().toISOString(),
        //adjstaff: 1 // Replace with actual staff ID if available
      });
  
      const idadjbill = adjBillResponse.data.idadjbill; // Get idadjbill from the created record
  
      // Step 2: Filter products where prodcount - oldstock > 0
      const filteredAdjProdData = adjProdData.filter(item => item.prodcount - item.oldstock !== 0);
  
      if (filteredAdjProdData.length === 0) {
        alert('No products to adjust.');
        return;
      }
  
      // Step 3: Create Adjprodsql records for each filtered product
      // Prepare the array of products to be created
const prodDataArray = filteredAdjProdData.map((item) => {
  const adjprodqty = item.prodcount - item.oldstock;
  return {
    adjprodname: item.idprodtoadd,
    adjbillidto: idadjbill,
    adjprodqty: adjprodqty,
    adjprodcomment: item.adjprodcomment,
    adjprodtimestamp: new Date().toISOString(),
  };
});

// Send a single POST request with all products
await axiosInstance.post('/Adjprodsql/bulk_create/', prodDataArray);
  
      // Step 4: Update all records in Adjprodtoadd by setting countforaddbillto to idadjbill
      await axiosInstance.post('/adjprodtoadd/bulk_update_countforaddbillto/', {
        countforaddbillto: idadjbill
      });
  
      // Success message
      alert('Save adjust success');
    } catch (error) {
      console.error('Error saving adjust stock:', error);
      alert('Error saving adjust stock');
    }
  };

  // Handle comment change from AdjBillToAddForm
  const handleAdjustCommentChange = (comment) => {
    setAdjustComment(comment);
  };

  return (
    <div className="container">
      <h2>ปรับสต็อก</h2>

      <AdjBillToAddForm onChange={handleAdjustCommentChange} />

      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary" onClick={handleAddProduct}>
          เพิ่มสินค้านับ
        </button>
        <button className="btn btn-success" onClick={handleSaveAdjustStock}>
          บันทึก
        </button>
      </div>

      <AdjProdToAddTable adjProdData={adjProdData} />

      <Modal show={showAddProductModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterForm
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
            seeAll={seeAll}
            handleSeeAllChange={handleSeeAllChange}
            handleRefresh={handleRefresh}
          />
          <StockTable 
            data={filteredStockData} 
            onEdit={setSelectedProduct} 
            seeAll={seeAll} 
            hideActions={true} // Pass hideActions as true when modal is displayed
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            ปิด  
          </button>
          <button className="btn btn-warning" onClick={handleAddOneToAdjust}>
            เพิ่ม 1 ชิ้น
          </button>
          <button className="btn btn-success" onClick={handleAddToAdjust}>
            เพิ่มทั้งหมด
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdjAddPage;