// src/components/main_sql/CustomerTable.js
import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../utils/CustomTable'; // Adjust the path as needed
import axiosInstance from '../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../redux/slices/columnSlice';

function CustomerTable({ onSelect }) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const searchInputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'customer')); // Using Redux to get selected columns

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/Customersql/');
        const activeItems = response.data.filter(item => !item.custcancel);
        setData(activeItems);
      } catch (error) {
        console.error('Error fetching customer data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Initialize selected columns in Redux if they haven't been set
  useEffect(() => {
    if (selectedColumns.length === 0) {
      dispatch(setColumns({ page: 'customer', columns: ['idcustomer', 'nickname', 'tel'] }));
    }
  }, [dispatch, selectedColumns]);

  // Focus the search input when the component mounts
  useEffect(() => {
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  // Handle search input changes
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Define columns
  const columns = [
    { key: 'idcustomer', label: 'ID' },
    { key: 'nickname', label: 'Name' },
    { key: 'tel', label: 'Telephone' },
  ];

  // Filter data based on search input
  const filteredData = data.filter(item =>
    item.nickname.toLowerCase().includes(filter.toLowerCase())
  );

  // Handle row clicks
  const handleRowClick = (customer) => {
    if (onSelect) {
      onSelect(customer);
    }
  };

  const onColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'customer', columns: newSelectedColumns })); // Update selected columns in Redux
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-3">
      <h2>ลูกค้า</h2>
      <form className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name"
              value={filter}
              onChange={handleFilterChange}
              ref={searchInputRef}
            />
          </div>
        </div>
      </form>

      <CustomTable
        data={filteredData}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
        onRowClick={handleRowClick}
        rowsPerPage={10}
        storageKey="customerTableSortState"
        page="customer" // Pass the page identifier
      />
    </div>
  );
}

export default CustomerTable;