// src/components/service_page/ServiceTable.js
import React, { useState, useEffect, useCallback } from 'react';
import CustomTable from '../../utils/CustomTable';
import DoWithLineModal from '../../utils/DoWithLineModal';
import axiosInstance from '../../utils/apiClient';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../redux/slices/columnSlice';
import { setExpandedRow, selectExpandedRow } from '../../redux/slices/expandedRowSlice';
import { fetchServiceData } from '../../redux/slices/serviceSlice';

function ServiceTable({ 
  data, 
  jobThingData, 
  jobServiceDoneData, 
  resetPage, 
  showCancelColumn, 
  onAddThing, 
  onAddjob, 
  onEditService, 
  onEditThing, 
  onEditJob,  
}) {
  const dispatch = useDispatch();
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'service'));
  const { expandedServiceId, expandedChildRowId } = useSelector(selectExpandedRow);

  const [expandedRow, setExpandedRowLocal] = useState(null);
  const [expandedChildRows, setExpandedChildRowsLocal] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalItemType, setModalItemType] = useState(null); // 'service', 'thing', 'job'
  const [selectedItem, setSelectedItem] = useState(null);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const columns = [
    {
      key: 'jobdate',
      label: 'วันที่',
      render: (service) => formatDate(service.jobdate),
    },
    {
      key: 'nickname',
      label: 'ลูกค้า',
      render: (service) => service.nickname,
    },
    {
      key: 'tel',
      label: 'โทร',
      render: (service) => service.tel,
    },
    {
      key: 'jobcatgroup',
      label: 'งาน',
      render: (service) => service.jobcatgroup,
      className: 'text-center',
    },
    {
      key: 'jobstatusname',
      label: 'สถานะ',
      render: (service) => service.jobstatusname,
      className: 'text-center',
    },
    {
      key: 'jobcomment',
      label: 'หมายเหตุ',
      render: (service) => service.jobcomment,
    },
    {
      key: 'staffname',
      label: 'คนจด',
      render: (service) => service.staffname,
    },
    {
      key: 'jobdatefinishplan',
      label: 'นัดส่ง',
      render: (service) => formatDate(service.jobdatefinishplan),
      className: 'text-center',
    },
    {
      key: 'realstart',
      label: 'เริ่ม',
      render: (service) => formatDate(service.realstart),
    },
    {
      key: 'realfinish',
      label: 'เสร็จ',
      render: (service) => formatDate(service.realfinish),
    },
    {
      key: 'idjobticket',
      label: 'ID',
      render: (service) => service.idjobticket,
    },
    {
      key: 'jobticketcancle',
      label: 'ยกเลิก',
      render: (service) => (service.jobticketcancle ? 'Yes' : '-'),
    },
  ];

  const getChildRows = (serviceId) => {
    return jobThingData.filter(
      job => Number(job.serviceid) === Number(serviceId) && job.servcancel === false
    );
  };

  const getLv2ChildRows = (servsellid) => {
    return jobServiceDoneData.filter(job => Number(job.servsellid) === Number(servsellid));
  };

  const toggleRowExpansion = useCallback((idjobticket) => {
    if (expandedRow === idjobticket) {
      setExpandedRowLocal(null);
      dispatch(setExpandedRow({ expandedServiceId: null, expandedChildRowId: null }));
      setExpandedChildRowsLocal({});
    } else {
      setExpandedRowLocal(idjobticket);
      dispatch(setExpandedRow({ expandedServiceId: idjobticket, expandedChildRowId: null }));
    }
  }, [dispatch, expandedRow]);

  const toggleChildRowExpansion = useCallback((parentId, childId) => {
    setExpandedChildRowsLocal((prev) => {
      const currentExpanded = prev[parentId] || null;
      const newExpandedChildRowId = currentExpanded === childId ? null : childId;
      
      // Only dispatch if there's an actual change
      if (newExpandedChildRowId !== currentExpanded) {
        setTimeout(() => {
          dispatch(setExpandedRow({ expandedServiceId: parentId, expandedChildRowId: newExpandedChildRowId }));
        }, 0); // Wrap the dispatch call in setTimeout to prevent updating state during render
      }
      
      return {
        ...prev,
        [parentId]: newExpandedChildRowId,
      };
    });
  }, [dispatch]);

  const onColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'service', columns: newSelectedColumns }));
  };

  // Use useEffect to set expanded state from Redux when the component mounts or when the state changes
  
  useEffect(() => {
  console.log("expandedServiceId from Redux:", expandedServiceId);
  console.log("expandedRowLocal:", expandedRow);
  console.log("expandedChildRowId from Redux:", expandedChildRowId);
  console.log("expandedChildRowsLocal:", expandedChildRows);
    if (expandedServiceId !== expandedRow) {
      setExpandedRowLocal(expandedServiceId);
    }

    if (
      expandedChildRowId !== null &&
      expandedServiceId !== null &&
      expandedChildRows[expandedServiceId] !== expandedChildRowId
    ) {
      setExpandedChildRowsLocal((prev) => ({
        ...prev,
        [expandedServiceId]: expandedChildRowId,
      }));
    }
  }, [expandedServiceId, expandedChildRowId, expandedRow, expandedChildRows]);

  const visibleColumnsCount = columns.reduce((count, column) => {
    if (selectedColumns && selectedColumns.includes(column.key)) {
      if (column.key === 'jobticketcancle' && !showCancelColumn) {
        return count;
      }
      return count + 1;
    }
    return count;
  }, 0);

  const formatNumber = (number) => {
    if (number == null || isNaN(number)) return '';
    return Number(number).toLocaleString('en-US'); // You can specify your locale
  };

  const handleRowDoubleClick = (service) => {
    setModalItemType('service');
    setSelectedItem(service);
    setShowModal(true);
  };

  const handleThingRowEditClick = (thing) => {
    setModalItemType('thing');
    setSelectedItem(thing);
    setShowModal(true);
    console.log('The catid for this row is:', thing.catid);
  };

  const handleJobRowDoubleClick = (job) => {
    setModalItemType('job');
    setSelectedItem(job);
    setShowModal(true);
  };

  const handleEdit = async () => {
    setShowModal(false);
    if (modalItemType === 'service') {
      onEditService(selectedItem);
    } else if (modalItemType === 'thing') {
      onEditThing(selectedItem);
    } else if (modalItemType === 'job') {
      onEditJob(selectedItem);
    }
  };

  const handleDelete = async () => {
    setShowModal(false);
    if (modalItemType === 'service') {
      await handleDeleteService(selectedItem.idjobticket);
    } else if (modalItemType === 'thing') {
      await handleDeleteThing(selectedItem.idservicesell);
    } else if (modalItemType === 'job') {
      // Handle delete for job
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      const response = await axiosInstance.patch(`/update-jobticketcancle/${serviceId}/`);

      if (response.status === 200) {
        alert('Service canceled successfully');
        dispatch(fetchServiceData()); // Refetch data after deletion
      } else {
        alert('Failed to cancel service');
      }
    } catch (error) {
      console.error('There was an error canceling the service!', error);
      alert('There was an error canceling the service!');
    }
  };

  const handleDeleteThing = async (idservicesell) => {
    try {
      const response = await axiosInstance.patch(`/update-thingcancle/${idservicesell}/`);
      
      if (response.status === 200) {
        alert('Thing has been successfully canceled.');
        dispatch(fetchServiceData()); // Refetch data after deletion
      } else {
        alert('Failed to cancel the thing.');
      }
    } catch (error) {
      console.error('Error canceling the thing:', error);
      alert('There was an error canceling the thing.');
    }
  };

  const handleRowClick = (service) => {
    toggleRowExpansion(service.idjobticket);
  };

  const handleDoubleClick = (service) => {
    handleRowDoubleClick(service);
  };
  // Function to handle the click event for the child row
const handleChildRowClick = (e, parentId, childId) => {
  e.stopPropagation(); // Prevent event bubbling
  toggleChildRowExpansion(parentId, childId);
};

  const renderRowExpansion = (service) => {
    if (expandedRow !== Number(service.idjobticket)) return null;
    return (
      <tr>
        <td colSpan={visibleColumnsCount}>
          <div style={{ width: '100%', margin: '10' }}>
            <table className="table table-sm no-border-table">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Size</th>
                  <th>Color</th>
                  <th>ID</th>
                  <th>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event bubbling
                        onAddThing(service.idjobticket); // Pass the service ID
                      }}
                    >
                      +
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {getChildRows(service.idjobticket).map((childRow) => (
                  <React.Fragment key={childRow.idservicesell}>
                    {/* Lv1 Child Row */}
                    <tr
                      onClick={(e) => handleChildRowClick(e, service.idjobticket, childRow.idservicesell)} 
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{childRow.categoryname}</td>
                      <td>{childRow.brandname}</td>
                      <td>{childRow.modelname}</td>
                      <td>{childRow.sizename}</td>
                      <td>{childRow.colorname}</td>
                      <td>{childRow.idservicesell}</td>
                      {/* Add an Edit button */}
                      <td style={{ verticalAlign: 'middle' }}>
                        <span
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event bubbling
                            handleThingRowEditClick(childRow); // Handle the edit action
                          }}
                        >
                          Edit
                        </span>
                      </td>
                    </tr>
                    {/* Lv2 Child Rows */}
                    {expandedChildRows[Number(service.idjobticket)] === Number(childRow.idservicesell) && (
                      <tr>
                        <td colSpan="4">
                          <div style={{ marginLeft: '40px' }}>
                            <table className="table table-sm no-border-table">
                              <thead>
                                <tr>
                                  <th>Service Name</th>
                                  <th>Price</th>
                                  <th>Discount</th>
                                  <th>Quantity</th>
                                  <th>Staff Name</th>
                                  <th>idServSellTo</th>
                                  <th>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onAddjob(childRow.idservicesell, service.idjobticket);
                                      }}
                                    >
                                      +
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getLv2ChildRows(childRow.idservicesell).map((lv2Row) => (
                                  <tr
                                    key={lv2Row.idservjob}
                                    onDoubleClick={(e) => {
                                      e.stopPropagation();
                                      handleJobRowDoubleClick(lv2Row);
                                    }}
                                  >
                                    <td>{lv2Row.servicename}</td>
                                    <td className="text-center">{formatNumber(lv2Row.servjobprice)}</td>
                                    <td className="text-center">{formatNumber(lv2Row.servjobdisc)}</td>
                                    <td className="text-center">{lv2Row.servjobqty}</td>
                                    <td>{lv2Row.staffname}</td>
                                    <td>{lv2Row.servsellid}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <CustomTable
        data={data}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
        rowsPerPage={10}
        renderRowExpansion={(service) => expandedRow === service.idjobticket && renderRowExpansion(service)}
        resetPage={resetPage}
        onRowClick={handleRowClick} 
        onRowDoubleClick={handleDoubleClick}
        page="service" // Pass the page identifier
      />
      <DoWithLineModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isDeleteConfirmation={false}
        itemName={
          modalItemType === 'service'
            ? selectedItem.nickname
            : modalItemType === 'thing'
            ? selectedItem.categoryname
            : modalItemType === 'job'
            ? selectedItem.servicename
            : ''
        }
        onEdit={handleEdit}
        onDelete={handleDelete}
        onConfirmDelete={() => {}}
        onCancelDeleteConfirmation={() => {}}
        showCreateNew={false} // Exclude 'นำไปสร้างใหม่' button
      />
    </>
  );
}

export default ServiceTable;