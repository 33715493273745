// src/components/StockTable.js

import React, { useState, useEffect } from 'react';
import CustomTable from '../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../redux/slices/columnSlice';

function StockTable({ data, onEdit, seeAll, hideActions }) {
  const dispatch = useDispatch();
  
  // Get selected columns from Redux store
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'stock'));

  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleRowClick = (product) => {
    setSelectedProductId(product.prodid);
    // No modal logic here, modal only opens on clicking Edit
  };

  const handleEditClick = (product) => {
    onEdit(product);  // Open the modal only when Edit is clicked
  };

  // Define the columns
  const columns = [
    { key: 'categoryname', label: 'Cat', render: (item) => item.categoryname },
    { key: 'typename', label: 'Type', render: (item) => item.typename },
    { key: 'brandname', label: 'Brand', render: (item) => item.brandname },
    { key: 'modelname', label: 'Model', render: (item) => item.modelname },
    { key: 'sizename', label: 'Size', render: (item) => item.sizename },
    { key: 'colorname', label: 'Color', render: (item) => item.colorname },
    {
      key: 'st',
      label: 'Stock',
      className: 'text-center',
      render: (item) => item.st,
    },
    {
      key: 'price',
      label: 'Price',
      className: 'text-center',
      render: (item) => (item.price ? item.price.toLocaleString() : ''),
    },
    { key: 'barcode', label: 'Barcode', render: (item) => item.barcode },
    { key: 'prodid', label: 'ID', render: (item) => item.prodid },
  ];

  if (seeAll) {
    columns.push({
      key: 'prodcancel',
      label: 'Cancel',
      render: (item) => (item.prodcancel ? 'Yes' : '-'),
    });
  }

  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      dispatch(setColumns({ page: 'stock', columns: columns.map((col) => col.key) }));
    }
  }, [dispatch, selectedColumns, columns]);

  const onColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page

: 'stock', columns: newSelectedColumns }));
  };

  const rowClassName = (item) => {
    return selectedProductId === item.prodid ? 'table-active' : '';
  };

  return (
    <div className="table-container">
      <CustomTable
        data={data}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
        onRowClick={handleRowClick}
        rowClassName={rowClassName}
        rowsPerPage={10}
        storageKey="stockTableSortState"
        page="stock"
        hideActions={hideActions} // Pass hideActions prop to CustomTable
        showAddButton={true} // or false, depending on your needs
        //onAddNew={handleAddNew} // your function to handle adding new items
        showEditColumn={true} // or false
        onEdit={handleEditClick} // your function to handle editing items
      />
    </div>
  );
}

export default StockTable;