import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Sidebar({ show, handleClose, username }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear tokens and username from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');  // Also remove the username

    // Redirect to the login page after logging out
    navigate('/login');
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" className="custom-sidebar">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex flex-column">
          {/* Same links as NavBar */}
          <Button variant="link" className="text-start mb-2" onClick={() => { handleClose(); navigate('/'); }}>
            Stock
          </Button>
          <Button variant="link" className="text-start mb-2" onClick={() => { handleClose(); navigate('/service'); }}>
            Service
          </Button>
          <Button variant="link" className="text-start mb-2" onClick={() => { handleClose(); navigate('/adjpage'); }}>
            Adjust
          </Button>
        </div>

        {/* Show username and logout at the bottom */}
        <div className="mt-auto d-flex flex-column">
          {username && (
            <div className="text-muted mb-2">Logged in as: {username}</div>
          )}
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Sidebar;