// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function SizeSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'idprodsize', label: 'ID' },
    { key: 'sizename', label: 'Size' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/sizesql/"
      columns={columns}
      itemName="Size"
      dataKey="sizename"
      idKey="idprodsize"
      pageName="size"
      storageKey="sizeSqlTableSortState"
      cancelKey="sizecancel"
    />
  );
}

export default SizeSqlTable;