// src/components/product_sql/SqlTable.js

import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../utils/CustomTable';
import axiosInstance from '../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../redux/slices/columnSlice';
import { Modal, Button } from 'react-bootstrap';
import DoWithLineModal from '../../utils/DoWithLineModal';

function SqlTable({
  onSelect,
  onClose,
  endpoint,
  columns,
  itemName,
  dataKey,
  idKey,
  pageName,
  storageKey,
  cancelKey = 'cancel',
}) {
  const dispatch = useDispatch();
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, pageName));
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const searchInputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  // State variables for creating an item
  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [creatingItem, setCreatingItem] = useState(false);
  const [createError, setCreateError] = useState(null);

  // State variables for action modal
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  // State variables for editing item
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItemName, setEditItemName] = useState('');
  const [editingItem, setEditingItem] = useState(false);
  const [editError, setEditError] = useState(null);

  // Fetch items on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(endpoint);
        const activeItems = response.data.filter((item) => !item[cancelKey]);
        setData(activeItems);
      } catch (error) {
        console.error(`Error fetching ${itemName.toLowerCase()} data:`, error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [endpoint, itemName, cancelKey]);

  // Set default columns if none are selected
  useEffect(() => {
    if (selectedColumns.length === 0) {
      dispatch(setColumns({ page: pageName, columns: columns.map((col) => col.key) }));
    }
  }, [dispatch, selectedColumns, columns, pageName]);

  // Focus on search input when data is loaded
  useEffect(() => {
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter data based on search input
  const filteredData = data.filter((item) =>
    item[dataKey].toLowerCase().includes(filter.toLowerCase())
  );

  // Handle row click (selection)
  const handleRowClick = (item) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  // Handle columns change
  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: pageName, columns: newSelectedColumns }));
  };

  // Open modal to create new item
  const handleOpenModal = () => {
    setNewItemName('');
    setCreateError(null);
    setShowModal(true);
  };

  // Close create item modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Create a new item
  const handleCreateItem = async () => {
    if (!newItemName.trim()) {
      setCreateError(`${itemName} name cannot be empty.`);
      return;
    }

    setCreatingItem(true);
    setCreateError(null);

    try {
      const response = await axiosInstance.post(endpoint, {
        [dataKey]: newItemName,
        [cancelKey]: false,
      });

      setData((prevData) => [...prevData, response.data]);
      setShowModal(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setCreateError(`${itemName} name already exists.`);
      } else {
        console.error(`Error creating ${itemName.toLowerCase()}:`, error);
        setCreateError(`Failed to create ${itemName.toLowerCase()}. Please try again.`);
      }
    } finally {
      setCreatingItem(false);
    }
  };

  // Handle edit click in action column
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setShowActionModal(true);
    setIsDeleteConfirmation(false);
  };

  // Close action modal
  const handleCloseActionModal = () => {
    setShowActionModal(false);
    setIsDeleteConfirmation(false);
  };

  // Open edit item modal
  const handleEditItem = () => {
    setShowActionModal(false);
    setEditItemName(selectedItem[dataKey]);
    setEditError(null);
    setShowEditModal(true);
  };

  // Update the item
  const handleUpdateItem = async () => {
    if (!editItemName.trim()) {
      setEditError(`${itemName} name cannot be empty.`);
      return;
    }

    setEditingItem(true);
    setEditError(null);

    try {
      const response = await axiosInstance.put(`${endpoint}${selectedItem[idKey]}/`, {
        [dataKey]: editItemName,
        [cancelKey]: selectedItem[cancelKey],
      });

      // Update the data in the table
      setData((prevData) =>
        prevData.map((item) => (item[idKey] === selectedItem[idKey] ? response.data : item))
      );
      setShowEditModal(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setEditError(`${itemName} name already exists.`);
      } else {
        console.error(`Error updating ${itemName.toLowerCase()}:`, error);
        setEditError(`Failed to update ${itemName.toLowerCase()}. Please try again.`);
      }
    } finally {
      setEditingItem(false);
    }
  };

  // Initiate delete confirmation
  const handleDeleteItem = () => {
    setIsDeleteConfirmation(true);
  };

  // Confirm deletion of item
  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.patch(`${endpoint}${selectedItem[idKey]}/`, { [cancelKey]: true });
      setData((prevData) => prevData.filter((item) => item[idKey] !== selectedItem[idKey]));
      setShowActionModal(false);
      setIsDeleteConfirmation(false);
    } catch (error) {
      console.error(`Error deleting ${itemName.toLowerCase()}:`, error);
    }
  };

  // Cancel delete confirmation
  const handleCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-3">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center">
        <h2>{itemName}</h2>
        <span
          className="text-danger"
          style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
          onClick={onClose}
          aria-label="Close"
          role="button"
        >
          ×
        </span>
      </div>

      {/* Search Input */}
      <div className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder={`Search by ${itemName} Name`}
              value={filter}
              onChange={handleFilterChange}
              ref={searchInputRef}
            />
          </div>
        </div>
      </div>

      {/* Custom Table */}
      <CustomTable
        data={filteredData}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={handleColumnsChange}
        onRowClick={handleRowClick}
        rowsPerPage={10}
        storageKey={storageKey}
        page={pageName}
        showAddButton={true} // Show the '+' button
        onAddNew={handleOpenModal} // Function to call when '+' is clicked
        showEditColumn={true} // Show the 'Edit' column
        onEdit={handleEditClick} // Function to call when 'Edit' is clicked
      />

      {/* Modal for creating a new item */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create {itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="itemName"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </div>
          {createError && <div className="alert alert-danger mt-2">{createError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreateItem} disabled={creatingItem}>
            {creatingItem ? 'Creating...' : 'Create'}
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing the item */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit {itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="editItemName"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
            />
          </div>
          {editError && <div className="alert alert-danger mt-2">{editError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateItem} disabled={editingItem}>
            {editingItem ? 'Editing...' : 'Edit'}
          </Button>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for edit/delete action */}
      <DoWithLineModal
        show={showActionModal}
        onHide={handleCloseActionModal}
        isDeleteConfirmation={isDeleteConfirmation}
        itemName={selectedItem ? selectedItem[dataKey] : ''}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        onConfirmDelete={handleConfirmDelete}
        onCancelDeleteConfirmation={handleCancelDeleteConfirmation}
        showCreateNew={false}
      />
    </div>
  );
}

export default SqlTable;