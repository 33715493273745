import React, { useState, useEffect } from 'react';
import { useZxing } from 'react-zxing';
import './BarcodeScanner.css'; // Import custom CSS for styling

const BarcodeScanner = ({ onBarcodeScanned }) => {
  const [scanResult, setScanResult] = useState('');
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');

  // Constraints for better focus and resolution
  const constraints = {
    video: {
      facingMode: { ideal: 'environment' },
      width: { ideal: 1280 }, 
      height: { ideal: 720 },
      focusMode: 'continuous', // Continuous autofocus
    },
  };

  // ZXing hook for barcode scanning
  const { ref, torch: { on, off, isOn, isAvailable } } = useZxing({
    constraints, // Pass in the camera constraints
    deviceId: selectedDeviceId,
    onDecodeResult(result) {
      const barcodeData = result.getText();
      setScanResult(barcodeData);
      onBarcodeScanned(barcodeData); // Pass the scanned data to parent
    },
    timeBetweenDecodingAttempts: 100, // Faster scanning interval
  });

  // Fetch available video devices (cameras)
  useEffect(() => {
    (async () => {
      try {
        const availableDevices = await navigator.mediaDevices.enumerateDevices();
        const availableVideoDevices = availableDevices.filter(device => device.kind === 'videoinput');
        setDevices(availableVideoDevices);

        if (availableVideoDevices.length > 0) {
          setSelectedDeviceId(availableVideoDevices[0].deviceId); // Default to the first camera
        } else {
          console.error('No cameras found');
        }
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    })();
  }, []);

  return (
    <div className="scanner-container">
      <h2>Barcode Scanner</h2>

      {/* Camera selection dropdown */}
      <label htmlFor="camera-select">Select Camera: </label>
      <select
        id="camera-select"
        onChange={(e) => setSelectedDeviceId(e.target.value)}
        value={selectedDeviceId}
      >
        {devices.map(device => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${device.deviceId}`}
          </option>
        ))}
      </select>

      {/* Video feed with overlay */}
      <div className="video-container">
        <video ref={ref} className="scanner-video" />
        <div className="overlay-line"></div> {/* Line overlay for barcode alignment */}
      </div>

      {/* Torch control */}
      {isAvailable && (
        <button onClick={() => (isOn ? off() : on())}>
          {isOn ? 'Turn off Torch' : 'Turn on Torch'}
        </button>
      )}

      {/* Display scan result */}
      {scanResult && (
        <div>
          <h3>Scanned Result:</h3>
          <p>{scanResult}</p>
        </div>
      )}
    </div>
  );
};

export default BarcodeScanner;