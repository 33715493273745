// src/components/Pagination.js
import React, { useState, useEffect, useMemo } from 'react';
import ReactPaginate from 'react-paginate';

function PaginationComponent({ data, rowsPerPage, renderTableRows, children }) {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [data.length]);

  // Use memoization to avoid unnecessary updates
  const currentRows = useMemo(() => {
    const offset = currentPage * rowsPerPage;
    return data.slice(offset, offset + rowsPerPage);
  }, [currentPage, data, rowsPerPage]);

  const pageCount = Math.ceil(data.length / rowsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  

  return (
    <>
      {/* Render the table rows through the passed function */}
      <div className="table-container">
      {renderTableRows(currentRows)}
      </div>
      {/* Conditionally render pagination only if there are multiple pages */}
      <div className="d-flex justify-content-end mb-3">
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />)}
        {/* Render custom buttons passed as children */}
        <div>{children}</div>
      </div>      
    </>
  );
}

export default PaginationComponent;