// src/components/service_page/ServicePage.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import FilterService from './FilterService';  
import ServiceTable from './ServiceTable';    
import ServiceAddForm from './ServiceAddForm';
import ServiceThingAddF from './ServiceThingAddF';
import ServiceJobAddF from './ServiceJobAddF'; 
import { useSelector, useDispatch } from 'react-redux';
import { fetchServiceData } from '../../redux/slices/serviceSlice'; // Adjust the path
import { fetchJobThingData, fetchJobServiceDoneData } from '../../redux/slices/jobSlice';

const ServicePage = () => {
  const [filteredData, setFilteredData] = useState([]); 
  const [resetPage, setResetPage] = useState(false);    
  const [filters, setFilters] = useState({
    customer: '',
    selectedStatus: 'not_completed', // Default to "ที่ยังไม่จบงาน"
    showAll: false,
    selectedJobCategory: 'หน้าร้าน'  // Add this line
  });
  // State variables for managing visibility
  const [showAddForm, setShowAddForm] = useState(false);
  const [showThingtoServAddform, setShowThingtoServAddform] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [expandedServiceId, setExpandedServiceId] = useState(null);
  const [selectedServsellId, setSelectedServsellId] = useState(null); // Add this line
  const [showJobAddform, setShowJobAddform] = useState(false);
  const [expandedChildRowId, setExpandedChildRowId] = useState(null); // Add this line

  const [selectedServiceForEdit, setSelectedServiceForEdit] = useState(null);
  const [selectedThingForEdit, setSelectedThingForEdit] = useState(null);
  const [selectedJobForEdit, setSelectedJobForEdit] = useState(null);

  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.service.status);
  const serviceData = useSelector((state) => state.service.serviceData); // Fetch data from Redux state
  const jobThingData = useSelector((state) => state.job.jobThingData);
  const jobServiceDoneData = useSelector((state) => state.job.jobServiceDoneData);

  useEffect(() => {
    if (fetchStatus === 'idle') {
      dispatch(fetchServiceData());
    }
    dispatch(fetchJobThingData()); // Fetch jobThingData
    dispatch(fetchJobServiceDoneData()); // Fetch jobServiceDoneData
  }, [fetchStatus, dispatch]);

  const apiKey = localStorage.getItem('api_key');


  useEffect(() => {
    dispatch(fetchJobThingData());    // Fetch jobThingData from the server
    dispatch(fetchJobServiceDoneData()); // Fetch jobServiceDoneData from the server
}, [dispatch]);

  // Extract unique job status options from the data
  const jobStatusOptions = [...new Set(serviceData.map(service => service.jobstatusname))];

  // Extract unique job category group options from the data
  const jobCategoryOptions = [...new Set(serviceData.map(service => service.jobcatgroup))];

  // Update filter state and apply filters
  const handleFilterChange = useCallback(({ customer, selectedStatus, showAll, selectedJobCategory }) => {
    setFilters({ customer, selectedStatus, showAll, selectedJobCategory });
  }, []);

  useEffect(() => {
    const filtered = serviceData.filter((service) => {
      if (
        filters.customer &&
        (!service.nickname || !service.nickname.toLowerCase().includes(filters.customer.toLowerCase()))
      ) {
        return false;
      }

      // Handle the "ที่ยังไม่จบงาน" filter
      if (filters.selectedStatus === 'not_completed' && service.jobstatusname === 'จบงาน') {
        return false;
      }

      // Filter by selected status, if it's not "not_completed"
      if (
        filters.selectedStatus &&
        filters.selectedStatus !== 'not_completed' &&
        service.jobstatusname !== filters.selectedStatus
      ) {
        return false;
      }

      // Filter by selected job category
      if (filters.selectedJobCategory && service.jobcatgroup !== filters.selectedJobCategory) {
        return false;
      }

      // Show/Hide canceled jobs based on "All" checkbox
      if (!filters.showAll && service.jobticketcancle) {
        return false;
      }

      return true;
    });

    setFilteredData(filtered);
    setResetPage((prev) => !prev);
  }, [filters, serviceData]);

  // Add state for selected columns
  // Initialize selectedColumns with data from localStorage if available
    const [selectedColumns, setSelectedColumns] = useState(() => {
    const savedColumns = localStorage.getItem('selectedColumns');
    return savedColumns ? JSON.parse(savedColumns) : [
      'jobdate',
      'nickname',
      'tel',
      'jobcatgroup',
      'jobstatusname',
      'jobcomment',
      'staffname',
      'jobdatefinishplan',
      'realstart',
      'realfinish',
      'idjobticket',
    ];
  });

  // Save selectedColumns to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  // logic for the ServiceAddForm
  const handleAddService = () => {
    setShowAddForm(true);
  };

  const handleFormClose = () => {
    setShowAddForm(false);
  };

  const handleFormSave = () => {
    dispatch(fetchServiceData()); // Refresh the service data
    setShowAddForm(false);
  };

  // Handle the '+' button click in ServiceTable
  const handleAddThing = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowThingtoServAddform(true);
  };

  const handleAddJob = (servsellid, serviceId) => {
    setSelectedServsellId(servsellid); 
    setSelectedServiceId(serviceId); 
    setShowJobAddform(true);
  };

  // Handle saving from ThingtoServAddform
  const handleThingFormSave = (updatedThing) => {
    // Dispatch the action to refetch jobThingData from the server
    dispatch(fetchJobThingData());
    setShowThingtoServAddform(false);
    setExpandedServiceId(selectedServiceId); // Expand the service row
};

  const handleJobFormSave = async () => {
    await fetchJobServiceDoneData(); // Fetch updated service jobs
    await fetchJobThingData();       // Fetch updated job things
    setShowJobAddform(false);
    setExpandedServiceId(selectedServiceId);
    setExpandedChildRowId(selectedServsellId);
  };

  // Handle canceling from ThingtoServAddform
  const handleThingFormCancel = () => {
    setShowThingtoServAddform(false);
  };

  const handleJobFormCancel = () => {
    setShowJobAddform(false);
  };

  const handleEditService = async (service) => {
    try {
      // Fetch customer data
      const customerResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Customersql/`, {
        params: { nickname: service.nickname },
        headers: { 'X-API-KEY': apiKey }
      });
      const customerData = customerResponse.data.find(customer => customer.nickname === service.nickname);
      const customeridto = customerData ? customerData.idcustomer : null;
  
      // Fetch job category data
      const jobcatResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Jobcatnamesql/`, {
        params: { jobcatgroup: service.jobcatgroup },
        headers: { 'X-API-KEY': apiKey }
      });
      const jobcatData = jobcatResponse.data.find(jobcat => jobcat.jobcatgroup === service.jobcatgroup);
      const jobcat = jobcatData ? jobcatData.idjobcat : null;
  
      // Fetch job status data
      const jobstatusResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Jobstatusnamesql/`, {
        params: { jobstatusname: service.jobstatusname },
        headers: { 'X-API-KEY': apiKey }
      });
      const jobstatusData = jobstatusResponse.data.find(jobstatus => jobstatus.jobstatusname === service.jobstatusname);
      const jobstatus = jobstatusData ? jobstatusData.idjobstatus : null;
  
      setSelectedServiceForEdit({ ...service, customeridto, jobcat, jobstatus });
      setShowAddForm(true);
    } catch (error) {
      console.error('Error fetching data for editing service:', error);
    }
  };

  const handleEditThing = async (thing) => {
    setSelectedThingForEdit(thing);
    setShowThingtoServAddform(true);
  }

  const handleEditJob = (job) => {
    setSelectedJobForEdit(job);
    setShowJobAddform(true);
  }

  return (
    <div className="container">
      <h2>
        {showAddForm
          ? 'สร้างใบงานใหม่'
          : showThingtoServAddform
          ? 'เพิ่มของมา Service'
          : showJobAddform
          ? 'เพิ่มงานที่ต้องทำ'
          : 'ใบงาน'}
      </h2>

      {/* Conditionally render components based on state */}
      {!showAddForm && !showThingtoServAddform && !showJobAddform && (
        <>
          <FilterService 
            onFilter={handleFilterChange} 
            jobStatusOptions={jobStatusOptions}
            jobCategoryOptions={jobCategoryOptions}
            selectedColumns={selectedColumns}
            onColumnsChange={setSelectedColumns}
            onAddService={handleAddService}
          />

          <ServiceTable 
            data={filteredData} 
            jobThingData={jobThingData}
            jobServiceDoneData={jobServiceDoneData}
            resetPage={resetPage} 
            showCancelColumn={filters.showAll}
            selectedColumns={selectedColumns}
            onAddThing={handleAddThing} // Pass the handler to ServiceTable
            onAddjob={handleAddJob} // Pass the handler to ServiceTable
            expandedServiceId={expandedServiceId} // Pass the service ID to expand
            expandedChildRowId={expandedChildRowId} // Add this line
            onEditService={handleEditService}
            onEditThing={handleEditThing}
            onEditJob={handleEditJob}
            fetchServiceData={() => dispatch(fetchServiceData())} // Use the Redux action
          />
        </>
      )}

      {showAddForm && (
        <ServiceAddForm
        formTitle={selectedServiceForEdit ? 'Edit Service' : 'Create New Service'}
          onSave={handleFormSave}
          onCancel={handleFormClose}
          service={selectedServiceForEdit}
          customeridto={selectedServiceForEdit?.customeridto}
          jobcat={selectedServiceForEdit?.jobcat}
          jobstatus={selectedServiceForEdit?.jobstatus}
        />
      )}

      {showThingtoServAddform && (
        <ServiceThingAddF
        serviceId={selectedServiceId}
        thing={selectedThingForEdit}
        onSave={handleThingFormSave}
        onCancel={handleThingFormCancel}
        />
      )}

      {showJobAddform && (
        <ServiceJobAddF
        servsellid={selectedServsellId}
        job={selectedJobForEdit}
        onSave={handleJobFormSave}
        onCancel={handleJobFormCancel}
        />
      )}
    </div>
  );
};

export default ServicePage;