// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function ModelSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'modelid', label: 'ID' },
    { key: 'modelname', label: 'Model' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/modelsql/"
      columns={columns}
      itemName="Model"
      dataKey="modelname"
      idKey="modelid"
      pageName="Model"
      storageKey="modelSqlTableSortState"
      cancelKey="modelcancel"
    />
  );
}

export default ModelSqlTable;