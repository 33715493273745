// src/components/service_page/ServiceAddForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomerTable from '../main_sql/CustomerTable';
import JobCategoryTable from './service_sql/JobCategoryTable';
import JobStatusTable from './service_sql/JobStatusTable';
import StaffNameTable from '../main_sql/StaffNameTable';
import { getToday } from '../../utils/dateFunc';

// Create an Axios instance with the API key set in the headers
const apiKey = localStorage.getItem('api_key');

const axiosInstance = axios.create({
  headers: { 'X-API-KEY': apiKey }
});

function ServiceAddForm({ service, onSave, onCancel, formTitle, customeridto, jobcat, jobstatus }) {
  const [formData, setFormData] = useState({
    nickname: '',
    tel: '',
    customeridto: null,
    jobcatgroup: '',
    jobcat: null,
    jobstatusname: '',
    jobstatus: null,
    jobcomment: '',
    staffname: '',
    jobstaffaddin: null,
    jobdatefinishplan: '',
    // Add other fields as necessary
  });
  const [isCustomerTableVisible, setIsCustomerTableVisible] = useState(false);
  const [isJobCategoryTableVisible, setIsJobCategoryTableVisible] = useState(false);
  const [isJobStatusTableVisible, setIsJobStatusTableVisible] = useState(false);
  const [isStaffNameTableVisible, setIsStaffNameTableVisible] = useState(false);
  const [apiError, setApiError] = useState(''); // State to store API error messages

  useEffect(() => {
    if (service) {
      setFormData({
        nickname: service.nickname || '',
        tel: service.tel || '',
        customeridto: customeridto || service.customeridto || (service.customerData ? service.customerData.idcustomer : null),
        jobcatgroup: service.jobcatgroup || '',
        jobcat: jobcat || service.idjobcat || (service.jobcatData ? service.jobcatData.idjobcat : null),
        jobstatusname: service.jobstatusname || '',
        jobstatus: jobstatus || service.idjobstatus || (service.jobstatusData ? service.jobstatusData.idjobstatus : null),
        jobcomment: service.jobcomment || '',
        staffname: service.staffname || '',
        jobstaffaddin: service.jobstaffaddin || null,
        jobdatefinishplan: service.jobdatefinishplan ? service.jobdatefinishplan.substring(0, 10) : '',

        // Include other fields as necessary
          });
    } else {
      // If adding a new service, initialize with default values
      setFormData({
        nickname: '',
        tel: '',
        customeridto: null,
        jobcatgroup: 'หน้าร้าน',
        jobcat: 5,
        jobstatusname: 'รอคิว',
        jobstatus: 6,
        jobcomment: '',
        staffname: '',
        jobstaffaddin: null,
        jobdatefinishplan: getToday(),
        // Include other fields as necessary
      });
    }
  }, [service, customeridto, jobcat, jobstatus]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handlers for showing selection tables
  const handleCustomerClick = () => {
    setIsJobCategoryTableVisible(false);
    setIsJobStatusTableVisible(false);
    setIsStaffNameTableVisible(false);
    setIsCustomerTableVisible(true);
  };

  const handleJobCategoryClick = () => {
    setIsJobCategoryTableVisible(true);
    setIsJobStatusTableVisible(false);
    setIsStaffNameTableVisible(false);
    setIsCustomerTableVisible(false);
  };

  const handleJobStatusClick = () => {
    setIsJobCategoryTableVisible(false);
    setIsJobStatusTableVisible(true);
    setIsStaffNameTableVisible(false);
    setIsCustomerTableVisible(false);
  };

  const handleStaffNameClick = () => {
    setIsJobCategoryTableVisible(false);
    setIsJobStatusTableVisible(false);
    setIsStaffNameTableVisible(true);
    setIsCustomerTableVisible(false);
  };

  // Handlers for selecting data from tables
  const handleCustomerSelect = (selectedItem) => {
    setFormData({
      ...formData,
      nickname: selectedItem.nickname,
      tel: selectedItem.tel,
      customeridto: selectedItem.idcustomer
    });
    setIsCustomerTableVisible(false);
  };

  const handleJobCategorySelect = (selectedItem) => {
    setFormData({
      ...formData,
      jobcatgroup: selectedItem.jobcatgroup,
      jobcat: selectedItem.idjobcat
    });
    setIsJobCategoryTableVisible(false);
  };

  const handleJobStatusSelect = (selectedItem) => {
    setFormData({
      ...formData,
      jobstatusname: selectedItem.jobstatusname,
      jobstatus: selectedItem.idjobstatus
    });
    setIsJobStatusTableVisible(false);
  };

  const handleStaffNameSelect = (selectedItem) => {
    setFormData({
      ...formData,
      staffname: selectedItem.staffname,
      jobstaffaddin: selectedItem.idstaff
    });
    setIsStaffNameTableVisible(false);
  };

  // Validate the form data
  const validateData = (data) => {
    const errors = {};

    if (!data.nickname) {
      errors.nickname = 'Customer is required';
    }
    // Add other validation rules as necessary

    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateData(formData);
    if (Object.keys(errors).length > 0) {
      alert('Please fix the errors in the form');
      return;
    }

       // Log the form data to the console
       console.log('Form data being sent:', formData);
    try {
      let response;
      if (formTitle === 'Edit Service' && service && service.idjobticket) {
        // Update the existing service
        response = await axiosInstance.put(`${process.env.REACT_APP_API_BASE_URL}/update-service/${service.idjobticket}/`, formData);
      } else {
        // Create a new service
        response = await axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/add-service/`, formData);
      }

      if (response.status === 201 || response.status === 200) {
        alert('Service saved successfully');
        onSave(response.data); // Call the onSave callback with the saved service data
      } else {
        alert('Failed to save service');
      }
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response) {
        // Server responded with a status other than 200 range
        setApiError(error.response.data.message || 'There was an error saving the data.');
      } else if (error.request) {
        // Request was made but no response received
        setApiError('No response received from the server.');
      } else {
        // Something else happened while setting up the request
        setApiError('An error occurred while setting up the request.');
      }
    }
  };

  return (
    <div className="add-or-edit-form">
      <h2>{formTitle}</h2>
      <form onSubmit={handleSubmit} method="POST">
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Nickname</label>
            <input
              type="text"
              className="form-control"
              name="nickname"
              value={formData.nickname}
              onChange={handleChange}
              onClick={handleCustomerClick}
              readOnly
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Telephone</label>
            <input
              type="text"
              className="form-control"
              name="tel"
              value={formData.tel}
              onChange={handleChange}
              onClick={handleCustomerClick}
              readOnly
              required
            />
          </div>
        </div>
        {/* Job Category and Job Status */}
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Job Category</label>
            <input
              type="text"
              className="form-control"
              name="jobcatgroup"
              value={formData.jobcatgroup}
              onChange={handleChange}
              onClick={handleJobCategoryClick}
              readOnly
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Job Status</label>
            <input
              type="text"
              className="form-control"
              name="jobstatusname"
              value={formData.jobstatusname}
              onChange={handleChange}
              onClick={handleJobStatusClick}
              readOnly
              required
            />
          </div>
        </div>
        {/* Job Comment */}
        <div className="row mb-3">
          <div className="col-md-12 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Job Comment</label>
            <textarea
              className="form-control"
              name="jobcomment"
              value={formData.jobcomment}
              onChange={handleChange}
              rows="3"
            />
          </div>
        </div>
        {/* Staff Name and Planned Finish Date */}
        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Staff Name</label>
            <input
              type="text"
              className="form-control"
              name="staffname"
              value={formData.staffname}
              onChange={handleChange}
              onClick={handleStaffNameClick}
              readOnly
              required
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="form-label me-2" style={{ width: '100px' }}>Planned Finish Date</label>
            <input
              type="date"
              className="form-control"
              name="jobdatefinishplan"
              value={formData.jobdatefinishplan}
              onChange={handleChange}
            />
          </div>
        </div>
       
        {/* Buttons */}
        <button type="submit" className="btn btn-primary">Save</button>
        <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>Cancel</button>
      </form>
      {/* Display API error message */}
      {apiError && (
        <div className="alert alert-danger mt-3">{apiError}</div>
      )}
      {/* Include selection components */}
      {isCustomerTableVisible && <CustomerTable onSelect={handleCustomerSelect} />}
      {isJobCategoryTableVisible && <JobCategoryTable onSelect={handleJobCategorySelect} />}
      {isJobStatusTableVisible && <JobStatusTable onSelect={handleJobStatusSelect} />}
      {isStaffNameTableVisible && <StaffNameTable onSelect={handleStaffNameSelect} />}
    </div>
  );
}

export default ServiceAddForm;