// src/components/utils/CustomTable.js

import React, { useCallback, useState } from 'react';
import PaginationComponent from '../components/Pagination'; 
import useSortable from '../hooks/useSortable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../redux/slices/columnSlice';
import './CustomTable.css';

function CustomTable({
  data,
  columns,
  rowsPerPage = 10,
  renderRowExpansion,
  resetPage,
  onRowClick,
  rowClassName,
  storageKey = 'customTableSortState',
  page,
  hideActions,
  // New props
  showAddButton = false,
  onAddNew,
  showEditColumn = false,
  onEdit,
}) {
  const { sortedData, handleSort, sortColumn, sortDirection } = useSortable(data, columns, storageKey);
  const dispatch = useDispatch();

  const selectedColumns = useSelector((state) => selectSelectedColumns(state, page));
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleColumnChange = (columnKey) => {
    const newSelectedColumns = selectedColumns.includes(columnKey)
      ? selectedColumns.filter((col) => col !== columnKey)
      : [...selectedColumns, columnKey];
    dispatch(setColumns({ page, columns: newSelectedColumns }));
  };

  const handleRowSelection = useCallback((rowData) => {
    setSelectedRowId(rowData.id);
    if (onRowClick) {
      onRowClick(rowData);
    }
  }, [onRowClick]);

  const renderTableRows = useCallback(
    (currentRows) => (
      <div className="table-container">
        <table className="table table-striped no-border-table">
          <thead>
            <tr>
              {columns.map((column) => {
                if (selectedColumns.includes(column.key)) {
                  const isSortable = column.sortable !== false;
                  return (
                    <th
                      key={column.key}
                      onClick={(e) => {
                        if (!isEditing && isSortable && e.target === e.currentTarget) {
                          handleSort(column.key);
                        }
                      }}
                      style={{
                        cursor: isEditing
                          ? 'not-allowed'
                          : isSortable
                          ? 'pointer'
                          : 'default',
                      }}
                      className={column.headerClassName}
                    >
                      {column.label}
                      {isSortable && sortColumn === column.key && (sortDirection === 'asc' ? ' ▼' : ' ▲')}
                    </th>
                  );
                }
                return null;
              })}
              {showAddButton && (
                <th>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onAddNew) {
                        onAddNew();
                      }
                    }}
                  >
                    +
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((rowData, rowIndex) => (
              <React.Fragment key={rowData.id || rowIndex}>
                <tr
                  onClick={() => handleRowSelection(rowData)}
                  className={`${rowClassName ? rowClassName(rowData) : ''} ${selectedRowId === rowData.id ? 'selected-row' : ''}`}
                  style={onRowClick ? { cursor: 'pointer' } : undefined}
                >
                  {columns.map((column) => {
                    if (selectedColumns.includes(column.key)) {
                      return (
                        <td key={column.key} className={column.className}>
                          {column.render ? column.render(rowData, setIsEditing) : rowData[column.key]}
                        </td>
                      );
                    }
                    return null;
                  })}
                  {showEditColumn && (
                    <td style={{ verticalAlign: 'middle' }} className='text-center'>
                      <span
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onEdit) {
                            onEdit(rowData);
                          }
                        }}>
                        Edit
                      </span>
                    </td>
                  )}
                </tr>
                {renderRowExpansion && renderRowExpansion(rowData)}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    ),
    [
      columns,
      handleSort,
      onRowClick,
      renderRowExpansion,
      rowClassName,
      selectedColumns,
      sortColumn,
      sortDirection,
      selectedRowId,
      handleRowSelection,
      isEditing,
      showEditColumn,
      onEdit,
      showAddButton,
      onAddNew,
    ]
  );

  return (
    <div>
      <PaginationComponent
        data={sortedData}
        rowsPerPage={rowsPerPage}
        renderTableRows={renderTableRows}
        resetPage={resetPage}
      />
      {!hideActions && (
        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-primary me-2"
            onClick={() => setShowColumnSelector(!showColumnSelector)}
          >
            Select Columns
          </button>
        </div>
      )}

      {showColumnSelector && (
        <div className="mt-2">
          <div className="card">
            <div className="card-body">
              <h5>Select Columns to Display</h5>
              {columns.map((column) => (
                <div key={column.key} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`column-${column.key}`}
                    checked={selectedColumns.includes(column.key)}
                    onChange={() => handleColumnChange(column.key)}
                  />
                  <label className="form-check-label" htmlFor={`column-${column.key}`}>
                    {column.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomTable;