// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import columnReducer from './slices/columnSlice'; 
import serviceReducer from './slices/serviceSlice';
import expandedRowSlice from './slices/expandedRowSlice';
import jobReducer from './slices/jobSlice';

const store = configureStore({
  reducer: {
    columns: columnReducer,
    service: serviceReducer,
    expandedRow: expandedRowSlice,
    job: jobReducer,
  },
});

export default store;