// src/components/product_sql/CatSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function CatSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'catid', label: 'ID' },
    { key: 'categoryname', label: 'Category' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/catsql/"
      columns={columns}
      itemName="Category"
      dataKey="categoryname"
      idKey="catid"
      pageName="category"
      storageKey="catSqlTableSortState"
      cancelKey="catcancle"
    />
  );
}

export default CatSqlTable;