// src/redux/slices/serviceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async action to fetch service data
export const fetchServiceData = createAsyncThunk(
  'service/fetchServiceData',
  async (_, { rejectWithValue }) => {
    try {
      const apiKey = localStorage.getItem('api_key');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/jobticketsqlv/`, {
        headers: {
          'X-API-KEY': apiKey,
        },
      });
      return response.data; // Return the data if successful
    } catch (error) {
      console.error('Error fetching service data:', error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Create a slice for service data
const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    serviceData: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    // Add any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServiceData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.serviceData = action.payload; // Store the fetched service data
      })
      .addCase(fetchServiceData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong'; // Ensure `error` is a string
      });
  },
});

export default serviceSlice.reducer;