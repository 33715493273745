// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main';
import ServicePage from './components/service_page/ServicePage';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import NavBar from './components/NavBar';
import Sidebar from './components/Sidebar';
import AdjAddPage from './components/adjust_page/AdjAddPage'; // Import your AdjAddPage component
import ChangePassword from './components/user_profile/ChangePassword'; // Import the ChangePassword component
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const username = localStorage.getItem('username');
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const handleLogout = () => {
    // Clear tokens and username from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('api_key');
    localStorage.removeItem('username');

    // Redirect to the login page using navigate
    navigate('/login', { replace: true });
  };

  return (
    <div>
      {localStorage.getItem('access_token') && (
        <>
          <NavBar username={username} handleLogout={handleLogout} handleSidebarToggle={handleSidebarToggle} />
          <Sidebar
            show={showSidebar}
            handleClose={() => setShowSidebar(false)}
            username={username}
            handleSelect={() => setShowSidebar(false)}
          />
        </>
      )}
      <Routes>
        {/* Public Route: Login */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="/service"
          element={
            <PrivateRoute>
              <ServicePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/adjpage" // Add your new route here
          element={
            <PrivateRoute>
              <AdjAddPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        {/* Catch-All Route */}
        <Route
          path="*"
          element={
            localStorage.getItem('access_token') ? <Navigate to="/" replace /> : <Navigate to="/login" replace />
          }
        />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;