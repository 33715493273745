// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function TypeSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'typeid', label: 'ID' },
    { key: 'typename', label: 'Size' },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/typesql/"
      columns={columns}
      itemName="Type"
      dataKey="typename"
      idKey="typeid"
      pageName="type"
      storageKey="typeSqlTableSortState"
      cancelKey="typecancel"
    />
  );
}

export default TypeSqlTable;