// src/redux/slices/columnSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

// Initialize columns state from localStorage or with an empty array if not found
const initialColumns = {
  stock: JSON.parse(localStorage.getItem('selectedColumnsStock') || '[]'),
  service: JSON.parse(localStorage.getItem('selectedColumnsService') || '[]'),
  customer: JSON.parse(localStorage.getItem('selectedColumnsCustomer') || '[]'),
  jobCategory: JSON.parse(localStorage.getItem('selectedColumnsJobCategory') || '[]'), 
  category: JSON.parse(localStorage.getItem('selectedColumnsCategory') || '[]'),
  brand: JSON.parse(localStorage.getItem('selectedColumnsBrand') || '[]'), 
  model: JSON.parse(localStorage.getItem('selectedColumnsModel') || '[]'), 
  size: JSON.parse(localStorage.getItem('selectedColumnsSize') || '[]'), 
  color: JSON.parse(localStorage.getItem('selectedColumnsColor') || '[]'), 
};

const columnSlice = createSlice({
  name: 'columns',
  initialState: initialColumns,
  reducers: {
    setColumns: (state, action) => {
      const { page, columns } = action.payload;
      state[page] = columns;
      
      // Store columns in localStorage using dynamic keys
      localStorage.setItem(`selectedColumns${capitalize(page)}`, JSON.stringify(columns));
    },
  },
});

// Utility function to capitalize the page name
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const { setColumns } = columnSlice.actions;

// Memoized selector function to retrieve the selected columns for the given page
export const selectSelectedColumns = createSelector(
  (state) => state.columns,
  (_, page) => page,
  (columns, page) => columns[page] || []
);

export default columnSlice.reducer;